<template>
  <div class="main">
    <div class="banner">
      <img src="@/static/activity/trainProject/banner.png" alt="">
    </div>
    <div class="content">
      <ul class="list">
        <li v-for="item in list">
          <h3>{{ item.title }}</h3>
          <h4>培训内容、培训要求及目的</h4>
          <div class="text line_clamp2" v-html="item.content">
          </div>
          <h4>就业方向</h4>
          <div class="text line_clamp1" v-html="item.jobSouse">
          </div>
          <img src="@/static/activity/trainProject/icon.png" alt="" class="icon" @click="getOpen(item)">
        </li>
      </ul>
      <status type='record' v-show="pages.total <= 0"/>
      <div class="pages" v-show="pages.total > 0">
        <el-pagination
            background
            class="work_pagination"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pages.pageNum"
            :page-size="pages.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next, total, jumper"
            :total="pages.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
        :title="data.title"
        :visible.sync="isShow"
        width="996px"
        :before-close="handleClose">
      <div class="dialog_content">
        <h4>
          培训内容、培训要求及目的
        </h4>
        <div class="text" v-html="data.content">
        </div>
        <h4>
          就业方向
        </h4>
        <div class="text" v-html="data.jobSouse">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      list: [],
      data: {},
      pages: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      isShow: false
    }
  },
  created() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.$api.getLzTrNoticeList(this.pages).then(res => {
        this.list = res.data.data;
        this.pages.total = res.data.total;
      })
    },
    getOpen(data) {
      this.data = data;
      this.isShow = true;
    },
    handleSizeChange(val) {
    },
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      this.getInfo(a);
      window.scrollTo(0, 0);
    },
    handleClose() {
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  .banner {
    img {
      width: 100%;
    }
  }

  .content {
    width: 1200px;
    margin: 0 auto;
    height: 660px;
    .list {
      display: flex;
      flex-wrap: wrap;

      li {
        position: relative;
        width: 588px;
        height: 246px;
        border-radius: 2px;
        background: white;
        margin-top: 24px;
        margin-right: 24px;
        padding: 18px;

        &:nth-of-type(2n) {
          margin-right: 0;
        }

        h3 {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 20px;
          color: #333333;
          letter-spacing: 0.67px;
          line-height: 30px;
        }

        h4 {
          font-family: PingFangSC-Medium;
          font-weight: 500;
          font-size: 16px;
          color: #333333;
          letter-spacing: 0.53px;
          line-height: 24px;
          margin-top: 18px;
        }

        .text {
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 16px;
          color: #666666;
          letter-spacing: 0.53px;
          text-align: justify;
          line-height: 24px;
          margin-top: 6px;
        }

        .icon {
          position: absolute;
          top: 20px;
          right: 18px;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep .el-dialog{
  .el-dialog__header{
    .el-dialog__title{
      font-size: 24px;
      color: #333333;
      letter-spacing: 0.8px;
      line-height: 40px;
      font-weight: 600;
    }
  }
  .el-dialog__body{
    padding: 0 24px 40px 24px;
    .dialog_content{
      h4{
        font-family: PingFangSC-Medium;
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0.53px;
        line-height: 24px;
        margin-top: 24px;
      }
      .text{
        font-family: PingFangSC-Regular;
        font-weight: 400;
        font-size: 16px;
        color: #666666;
        letter-spacing: 0.53px;
        text-align: justify;
        line-height: 24px;
        margin-top: 6px;
      }
    }
  }
}
</style>
